import React, { useRef } from "react";
import Layout from "../components/layout";
import Background from "../components/Background";
import GoogleMap from "../components/GoogleMap";
import HomeFeature1 from "../components/HomeFeature1";
import Footer from "../components/Footer";
import { PageContent, PageHeader, Section, SectionForm } from "../styles/";
import { graphql, useStaticQuery } from "gatsby";
import Helmet from "react-helmet";
import { HomeIcon, PhoneIcon, FacebookIcon, InstagramIcon } from "../styles";
import styled from "styled-components";

const HomePageTemplate = (props) => {
  const contactForm = useRef(null);
  return (
    <Layout>
      <Helmet>
        <html lang="en" />
        <title>Peace By Piece CBT</title>
      </Helmet>
      <div style={{ background: "#1e2436" }}>
        <Background>
          <BackgroundSection>
            <div className="container">
              <div className="row">
                <div className="col col-lg-12">
                  <div style={{ textAlign: "center" }}>
                    <SubHeading>
                      Your road to <strong>better mental health</strong> starts here.
                    </SubHeading>
                  </div>
                </div>
              </div>
            </div>
          </BackgroundSection>
        </Background>
        <div style={{ background: "#edf1f5" }}>
          <HomeFeature1 />
        </div>
        <GoogleMap />
        <div className="container">
          <SectionHeading>Contact</SectionHeading>
        </div>
        <Footer contactForm={true} />
      </div>
    </Layout>
  );
};

const SectionHeading = styled.h2`
  margin: 3rem 0 0;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-size: 2.2rem;
  font-weight: bold;
  text-align: center;
`;

const BackgroundSection = styled.div`
  display: flex;
  min-height: 100vh;
`;

const MainHeading = styled.h1`
  display: inline-block;
  margin: 6rem 0 0 0;
  padding: 1rem;
  font-size: 4rem;
  color: #ffffff;
  background: rgb(93, 151, 88);
`;

const SubHeading = styled.span`
  margin: 8rem 0 0;
  padding: 1rem;
  font-size: 2.4rem;
  display: inline-block;
  background: rgb(30, 36, 54);
  color: #ffffff;

  strong {
    font-weight: bold;
  }
`;

export default HomePageTemplate;
