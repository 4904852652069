import React, { useState, useLayoutEffect, useEffect } from "react";

export default function GoogleMap() {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2239.829637706004!2d-4.431735222696963!3d55.848270973119774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48884949b5e705a1%3A0x70017bcf5b5cb14b!2sSt%20James%20House%2C%2025%20St%20James&#39;%20St%2C%20Paisley%20PA3%202HQ!5e0!3m2!1sen!2suk!4v1716617116814!5m2!1sen!2suk"
      width="100%"
      height="450px"
      frameBorder="0"
      style={{ border: 0 }}
      allowFullScreen={false}
      aria-hidden="false"
      tabIndex={0}
    ></iframe>
  );
}
