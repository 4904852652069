import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import HomeFeature1Background from "../HomeFeature1Background";
import styled from "styled-components";

export default () => {
  return (
    <div>
      <HomeFeature1Background className="homeFeature1">
        <div className="container">
          <div className="row">
            <div className="col col-lg-6">
              <HomeFeature1Content>
                <div className="homeFeature1Content"></div>
              </HomeFeature1Content>
            </div>
          </div>
        </div>
      </HomeFeature1Background>
    </div>
  );
};

const CTAGroup = styled.div`
  display: flex;
  padding: 1.5rem 0 0;
`;

const WordImg = styled.img`
  padding: 3rem 0 0;

  @media (max-width: 1024px) {
    padding: 1.5rem 0 2.5rem;
  }
`;

const HomeFeature1Content = styled.div`
  padding: 0rem 0 0 0;

  h2 {
    font-size: 2.5rem;
    color: #ffffff;
    font-weight: bold;
    text-transform: uppercase;
    background
  }

  .homeFeature1Content {
    color: #ffffff;
    font-size: 2.1rem;
    margin: 4rem -9rem 0;

    .listGroup {
      margin: 2rem 0;
      line-height: 1.5;
    }

  }
`;

const CTA = styled.a`
  display: inline-block;
  background: #43adda none repeat scroll 0% 0%;
  color: #ffffff;
  text-decoration: none;
  padding: 1rem;
  cursor: pointer;
  border-radius: 5px;
  margin: 1rem 0 0;
`;
