import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";

import BackgroundImage from "gatsby-background-image";
// Use the following to support legacy browsers like IE11:
// import BackgroundImage from 'gatsby-background-image-es5'
import { generateMedia } from "styled-media-query";

const media = generateMedia();

/**
 * In this functional component a <BackgroundImage />  is compared to an <Img />.
 * @param className   string    className(s) from styled-components.
 * @param children    nodes     Child-components from index.js
 * @return {*}
 * @constructor
 */
const BackgroundSection = ({ className, children }) => {
  const { desktop, mobile } = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "bg.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mobile: file(relativePath: { eq: "400w.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 414) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const sources = [
    mobile.childImageSharp.fluid,
    {
      ...desktop.childImageSharp.fluid,
      media: `(min-width: 900px)`,
    },
  ];
  return (
    <>
      <StyledSymetryWrapper>
        <BackgroundImage
          Tag="section"
          className={className}
          // To style via external CSS see layout.css last examples:
          // className="test"
          fluid={sources}
          backgroundColor={`#040e18`}
          // Title get's passed to both container and noscriptImg.
          title="Peace By Piece"
          // style={{
          //   // Defaults are overwrite-able by setting one of the following:
          //   // backgroundSize: '',
          //   // backgroundPosition: '',
          //   // backgroundRepeat: '',
          // }}
          // To "force" the classic fading in of every image (especially on
          // imageData change for fluid / fixed) by setting `soft` on `fadeIn`:
          // fadeIn={`soft`}
          // To be able to use stacking context changing elements yourself,
          // set this to true to disable the "opacity hack":
          // preserveStackingContext={true}
          // You can "safely" (look them up beforehand ; ) add other props:
          id="peaceByPiece"
          role="img"
          aria-label="Peace By Piece"
        >
          {children}
        </BackgroundImage>
      </StyledSymetryWrapper>
    </>
  );
};

const StyledSymetryWrapper = styled.div`
  width: 100vw;
  overflow: hidden;
`;

const StyledWelcomeImage = styled(Img)`
  width: 100vw;
  height: auto;
`;

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100vw;
  min-height: 100vh;
`;

export default StyledBackgroundSection;
